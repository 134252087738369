<template>
  <a-modal :visible="integration.modalSuccessAddShop" :wrap-class-name="$style.modalSync" :closable="false">
    <a-icon type="check-circle" />
    <h4 class="mt-5">
      {{ $t('add_store.sync_store_sucess') }}
    </h4>
    <p class="mt-2 text-center">
      {{ $t('add_store.des_sync_store_sucess') }}
    </p>
    <template #footer>
      <!-- <a-button key="back" class="mr-2" @click="typeof back !== 'undefined' && back(), openModalSuccess()">
        {{ $t('add_store.add_more_store') }}
      </a-button> -->
      <a-button key="submit" type="primary" @click="closeModalSuccess()">
        OK
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'ModalSyncSuccess',
  // props: {
    // visible: Boolean,
    // back: Function,
    // openModalSuccess: Function,
  // },
  mixins: [getQueryParam],
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  methods: {
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    closeModalSuccess() {
      this.$router.replace(this.to(this.$route.path))
      this.$store.dispatch('integration/CANCELSTOREMARKETPLACE')
      setTimeout(() => {
        this.$router.go()
      }, 100)
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>